import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css"



export const AboutPageTemplate = ({
  image,
  image2,
  headline1,
  content,
  contentComponent
}) => {

  const PageContent = contentComponent || Content
  return (
    <>
      <section className="has-background-primary">
        <div className="columns is-desktop">
          <div className="column is-full-mobile is-half-desktop has-text-white">
            <div className="section" style={{
              maxWidth: '700px',
              margin: '0 auto'
            }}>
              <h1 className="mt-6">{headline1}</h1>
              <p>Studies show that companies in the top quartile for gender, racial and ethnic diversity are more likely to have financial returns above their national industry medians.* Employees are taking note, too. A Glassdoor survey found that a diverse workplace is one of the main factors a potential employee considers before taking a job, and more than half (57%) of those already employed believe their company should be doing more to increase diversity among its workforce.**</p>

              <p>Put simply, if your company wants to compete financially and hire top talent, you need to get serious about creating a diverse, equitable and inclusive workplace.</p>

              <p className="smallest pt-6 mt-6">*McKinsey &amp; Company “Delivering through diversity” January 2018<br />
                **Glassdoor “What Job Seekers Really Think About Your Diversity and Inclusion Stats” November 2014
              </p>
            </div>
          </div>
          <div className="column is-full-mobile is-half-desktop p-0">
            <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} offset={0} style={{ height: '100%', width: '100%' }}>
              <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image
                    })`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100%', minHeight: '400px'
                }}
              ></div>
            </ScrollAnimation>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="columns is-centered">
            <div className="column is-10">
              <div className="section has-text-centered">
                <ScrollAnimation animateIn="animate__flipInX">
                  <h1>
                    But, <mark>the path to achieving a diverse and inclusive workspace isn’t always clear.</mark>
                  </h1>
                </ScrollAnimation>
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="has-background-info py-5">
        <div className="columns is-desktop py-5">
          <div className="column is-full-mobile is-half-desktop has-text-white">
            <div className="section has-text-centered" style={{
              maxWidth: '700px',
              margin: '0 auto'
            }}>
              <ScrollAnimation animateIn="animate__fadeInDown" offset={0} delay={1000} duration={0.5}>
                <div className="side-arrows-left">
                  <h2>Top Down</h2>
                  <p className="mb-0"><span className="has-text-warning">WHO:</span> HR, CEOs, leadership</p>
                  <p className="mb-0"><span className="has-text-warning">WHAT:</span> mandatory trainings and policy changes</p>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__bounceInLeft" offset={0}>
                <hr />
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInUp" offset={0} delay={1500} duration={0.5}>
                <div className="side-arrows-right">
                  <h2>Bottom Up</h2>
                  <p className="mb-0"><span className="has-text-warning">WHO:</span> employee resource groups</p>
                  <p className="mb-0"><span className="has-text-warning">WHAT:</span> discussing and implementing change</p>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          <div className="column is-full-mobile is-half-desktop has-text-white">
            <div className="section" style={{
              maxWidth: '700px',
              margin: '0 auto'
            }}>
              <h1 className="has-text-white">A Two-Part Solution.</h1>

              <p>Most companies approach their diversity, equity and inclusion goals either top-down, through mandatory training and policy changes, or bottom-up, with employee resource groups devoted to discussing and implementing change.</p>

              <p>Babes Helpin’ Babes has found that both leadership and employee involvement is needed to create real, meaningful change when shifting company culture.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="has-background-warning">
        <div className="container py-5">
          <div className="columns is-centered">
            <div className="column is-11">
              <div className="section has-text-centered">
                <p className="has-text-weight-bold">
                  We focus on equipping employees with the tools they need to start and maintain an employee resource group, like a Babes Helpin’ Babes chapter. At the same time, we consult with leadership and advise on the best ways to create an inclusive work environment that’s supportive of employees and encourages change.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="columns is-desktop">
          <div className="column is-full-mobile is-half-desktop">
            <div className="section" style={{
              maxWidth: '700px',
              margin: '0 auto'
            }}>
              <h1>This Starts With Uncomfortable Conversation.</h1>
              <p>You can’t fix what you don’t talk about, but according to a recent study, 70% of employees are avoiding difficult conversations with their boss, colleagues or direct reports.* To truly create ongoing change, employees need to feel comfortable showing up as their authentic selves, talking about their lived experiences and identifying areas for growth within the company.

              </p>
              <p className="smallest pt-6 mt-6">*Vital Smarts “Costly Conversations: Why the Way Employees Communicate Will Make or Break Your Bottom Line” December 6, 2016</p>
            </div>
          </div>
          <div className="column is-full-mobile is-half-desktop p-0">
            <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} style={{ height: '100%' }}>
              <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url(${!!image2.childImageSharp ? image2.childImageSharp.fluid.src : image2
                    })`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100%', minHeight: '300px'
                }}
              ></div>
            </ScrollAnimation>
          </div>
        </div>
      </section>
      <section className="has-background-primary py-5">
        <div className="container py-5">
          <div className="columns is-centered py-5">
            <div className="column is-11">
              <div className="section has-text-centered">
                <ScrollAnimation animateIn="animate__flipInX">
                  <h1>
                    Together, we’ll <mark>uplift the voices of women
                      and <span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span>, challenge norms and create meaningful change</mark> in your office culture.
                  </h1>
                </ScrollAnimation>
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="columns is-centered">
            <div className="column is-11">
              <div className="section has-text-centered">
                <Link to="/get-started" className="btn btn-one mt-6">CREATE CHANGE TODAY</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

AboutPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  headline1: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplate
        image={frontmatter.image}
        image2={frontmatter.image2}
        contentComponent={HTMLContent}
        headline1={post.frontmatter.headline1}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        headline1
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
